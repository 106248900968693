import { useTranslation } from 'react-i18next';
import { boolean, string } from 'yup';

const useRegisterParticipantGroupSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');

  return {
    participantMdmid: string()
      .required(requiredLabel),
    participantTimezone: string()
      .required(requiredLabel),
    participantMobilePhoneNumber: string()
      .nullable(true),
    participantJoiningChannel: string().required(requiredLabel),
    isPrivilegedParticipant: boolean(),
    participantSelectedMultiLanguage: string()
      .required(requiredLabel),
  };
};

export default useRegisterParticipantGroupSchema;
