export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  ObjectId: any;
  Upload: any;
};

export type Accommodation = {
  __typename?: 'Accommodation';
  endDate: Scalars['DateTime'];
  hotel: Hotel;
  startDate: Scalars['DateTime'];
  status: RequestStatus;
  transfer: Transfer;
};

export type AccommodationInput = {
  endDate: Scalars['DateTime'];
  hotel: HotelInput;
  startDate: Scalars['DateTime'];
  status: RequestStatus;
  transfer: TransferInput;
};

export type AgendaEntry = {
  __typename?: 'AgendaEntry';
  description?: Maybe<Scalars['String']>;
  session: Session;
  title: Scalars['String'];
};

export type AgendaEntryInput = {
  description?: InputMaybe<Scalars['String']>;
  session: SessionInput;
  title: Scalars['String'];
};

export type AgentEvent = {
  __typename?: 'AgentEvent';
  agentId: Scalars['String'];
  agentType: AgentType;
  events: Array<Event>;
};

export enum AgentType {
  guest = 'guest',
  janrainGroup = 'janrainGroup',
  janrainUser = 'janrainUser',
  oktaUser = 'oktaUser'
}

export enum AssetTarget {
  agendaFile = 'agendaFile',
  cardImage = 'cardImage',
  detailsImage = 'detailsImage',
  hotelBooking = 'hotelBooking',
  logoImage = 'logoImage',
  participantCsv = 'participantCsv',
  programLogo = 'programLogo',
  speakerImage = 'speakerImage',
  teaser = 'teaser',
  transportTicket = 'transportTicket'
}

export enum AssetType {
  csv = 'csv',
  document = 'document',
  image = 'image',
  video = 'video'
}

export type AssetsByTarget = {
  __typename?: 'AssetsByTarget';
  agendaFile?: Maybe<Array<AssetsEvent>>;
  cardImage?: Maybe<Array<AssetsEvent>>;
  programLogo?: Maybe<Array<AssetsEvent>>;
  teaser?: Maybe<Array<AssetsEvent>>;
};

export type AssetsByTargetInput = {
  agendaFile?: InputMaybe<Array<Scalars['String']>>;
  cardImage?: InputMaybe<Array<Scalars['String']>>;
  programLogo?: InputMaybe<Array<Scalars['String']>>;
  teaser?: InputMaybe<Array<Scalars['String']>>;
};

export type AssetsEvent = {
  __typename?: 'AssetsEvent';
  _id: Scalars['String'];
  assetTarget?: Maybe<AssetTarget>;
  assetType?: Maybe<AssetType>;
  bucket: Scalars['String'];
  eTag: Scalars['String'];
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type AssetsEventInput = {
  _id: Scalars['String'];
  assetTarget?: InputMaybe<AssetTarget>;
  assetType?: InputMaybe<AssetType>;
  bucket: Scalars['String'];
  eTag: Scalars['String'];
  key: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type BiogenLincConfig = {
  __typename?: 'BiogenLincConfig';
  checkConsent?: Maybe<Scalars['Boolean']>;
  checkGuestTextMessageConsent?: Maybe<Scalars['Boolean']>;
  detailsPageUrl?: Maybe<Scalars['String']>;
  enableGuestRegistration?: Maybe<Scalars['Boolean']>;
  enableGuestRegistrationCountryDropdown?: Maybe<Scalars['Boolean']>;
  fullForm?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isSwitzerlandSpecific?: Maybe<Scalars['Boolean']>;
  isVisibleOnBiogenLinc?: Maybe<Scalars['Boolean']>;
  prcCodeEventDetails?: Maybe<Scalars['String']>;
  prcCodeEventsListCard?: Maybe<Scalars['String']>;
  registration?: Maybe<BiogenLincConfigRegistration>;
  restrictedTo?: Maybe<Array<Scalars['String']>>;
  showSendInvitations?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
};

export type BiogenLincConfigInput = {
  checkConsent?: InputMaybe<Scalars['Boolean']>;
  checkGuestTextMessageConsent?: InputMaybe<Scalars['Boolean']>;
  detailsPageUrl?: InputMaybe<Scalars['String']>;
  enableGuestRegistration?: InputMaybe<Scalars['Boolean']>;
  enableGuestRegistrationCountryDropdown?: InputMaybe<Scalars['Boolean']>;
  fullForm?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  isSwitzerlandSpecific?: InputMaybe<Scalars['Boolean']>;
  isVisibleOnBiogenLinc?: InputMaybe<Scalars['Boolean']>;
  prcCodeEventDetails?: InputMaybe<Scalars['String']>;
  prcCodeEventsListCard?: InputMaybe<Scalars['String']>;
  registration?: InputMaybe<BiogenLincConfigRegistrationInput>;
  restrictedTo?: InputMaybe<Array<Scalars['String']>>;
  showSendInvitations?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type BiogenLincConfigRegistration = {
  __typename?: 'BiogenLincConfigRegistration';
  limitSeats: Scalars['Boolean'];
  registerBeforeDays: Scalars['Float'];
  validate: Scalars['Boolean'];
};

export type BiogenLincConfigRegistrationInput = {
  limitSeats: Scalars['Boolean'];
  registerBeforeDays: Scalars['Float'];
  validate: Scalars['Boolean'];
};

export type Booking = {
  __typename?: 'Booking';
  _id: Scalars['String'];
  end: Scalars['DateTime'];
  event: Event;
  format: Format;
  hostProvider: HostProvider;
  license: Scalars['String'];
  start: Scalars['DateTime'];
};

export type Contact = {
  __typename?: 'Contact';
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  topic: Scalars['String'];
};

export type ContactInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  topic: Scalars['String'];
};

export type CventConfig = {
  __typename?: 'CventConfig';
  sourceUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CventConfigInput = {
  sourceUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DocumentData = {
  __typename?: 'DocumentData';
  documentNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  props?: Maybe<Array<InsciteFieldData>>;
  status?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type DocumentInputDataField = {
  __typename?: 'DocumentInputDataField';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentInputDataFieldInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentPropertyResponse = {
  __typename?: 'DocumentPropertyResponse';
  defaultValue?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  editable: Scalars['Boolean'];
  helpContent?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  required: Scalars['Boolean'];
  section?: Maybe<Scalars['String']>;
  sectionPosition?: Maybe<Scalars['Float']>;
  setOnCreateOnly?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
};

export type DocumentTypeData = {
  __typename?: 'DocumentTypeData';
  fields?: Maybe<Array<DocumentPropertyResponse>>;
  label?: Maybe<Scalars['String']>;
  subtypes?: Maybe<Array<DocumentTypeReference>>;
  value?: Maybe<Scalars['String']>;
};

export type DocumentTypeReference = {
  __typename?: 'DocumentTypeReference';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type EmailAssetContext = {
  agendafile?: InputMaybe<Scalars['String']>;
  author1?: InputMaybe<Scalars['String']>;
  author2?: InputMaybe<Scalars['String']>;
  author3?: InputMaybe<Scalars['String']>;
  biogenlogo?: InputMaybe<Scalars['Boolean']>;
  buttoncolor?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  emailprccode?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  footerdisclaimertext?: InputMaybe<Scalars['String']>;
  footernote?: InputMaybe<Scalars['String']>;
  footernoteemailtypes?: InputMaybe<Array<Scalars['String']>>;
  headernote?: InputMaybe<Scalars['String']>;
  headernoteemailtypes?: InputMaybe<Array<Scalars['String']>>;
  joiningchannel?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  plink?: InputMaybe<Scalars['String']>;
  programlogourl?: InputMaybe<Scalars['String']>;
  questionid?: InputMaybe<Scalars['String']>;
  signatureemailaddress?: InputMaybe<Scalars['String']>;
  signaturefromname?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  veevacountry?: InputMaybe<Scalars['String']>;
};

export type ErrorResponseError = {
  __typename?: 'ErrorResponseError';
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  _id?: Maybe<Scalars['String']>;
  contentfulUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<EventPresence>;
  description?: Maybe<Scalars['String']>;
  externalFormat: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  externalPresence: Scalars['String'];
  externalStatus: Scalars['String'];
  format?: Maybe<Format>;
  isRsvpType?: Maybe<Scalars['Boolean']>;
  lastTestAt?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  market: Market;
  originMarket?: Maybe<OriginMarket>;
  owner?: Maybe<OktaPublicUserInfo>;
  presence: Presence;
  source?: Maybe<Source>;
  status: EventStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EventInput = {
  format: Format;
  hostProvider: HostProvider;
  market: Market;
  originMarket?: InputMaybe<OriginMarket>;
  owner: OktaPublicUserInfoInput;
  presence: Presence;
  product?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<SessionInput>;
  title: Scalars['String'];
  venue: Scalars['String'];
};

export type EventPresence = HybridEvent | OnlineEvent | PhysicalEvent;

export enum EventStatus {
  draft = 'draft',
  published = 'published',
  ready = 'ready',
  unpublished = 'unpublished'
}

export type EventsFilterInput = {
  format?: InputMaybe<Format>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  isPast?: InputMaybe<Array<Scalars['Boolean']>>;
  isRsvpType?: InputMaybe<Array<Scalars['Boolean']>>;
  market?: InputMaybe<Market>;
  presence?: InputMaybe<Presence>;
  sessionFilter?: InputMaybe<SessionFilterInput>;
  source?: InputMaybe<Source>;
  status?: InputMaybe<Array<EventStatus>>;
};

export type FileDataInput = {
  assetTarget?: InputMaybe<AssetTarget>;
  assetType?: InputMaybe<AssetType>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
};

export type FindSpeakerResponse = {
  __typename?: 'FindSpeakerResponse';
  count: Scalars['Float'];
  speakers: Array<SpeakerWithRelatedEvents>;
};

export enum Format {
  advisoryBoard = 'advisoryBoard',
  biogenMeeting = 'biogenMeeting',
  congress = 'congress',
  liveStreaming = 'liveStreaming',
  meeting = 'meeting',
  onDemand = 'onDemand',
  peerToPeer = 'peerToPeer',
  standalone = 'standalone',
  symposium = 'symposium',
  webinar = 'webinar'
}

export type GetInsciteDocumentsData = {
  __typename?: 'GetInsciteDocumentsData';
  insciteDocuments?: Maybe<Array<DocumentData>>;
};

export type GetInsciteFieldData = {
  __typename?: 'GetInsciteFieldData';
  insciteFieldData?: Maybe<Array<InsciteFieldData>>;
};

export type GetPicklistValues = {
  __typename?: 'GetPicklistValues';
  picklistValues?: Maybe<Array<PicklistFieldData>>;
};

export enum HostProvider {
  other = 'other',
  zoom = 'zoom'
}

export type Hotel = {
  __typename?: 'Hotel';
  assetId: Scalars['String'];
  contactPhone: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  website: Scalars['String'];
};

export type HotelInput = {
  assetId: Scalars['String'];
  contactPhone: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  website: Scalars['String'];
};

export type HybridEvent = {
  __typename?: 'HybridEvent';
  agenda?: Maybe<Array<AgendaEntry>>;
  agendaFileDescription?: Maybe<Scalars['String']>;
  agendaFileTitle?: Maybe<Scalars['String']>;
  assets?: Maybe<AssetsByTarget>;
  biogenLincConfig?: Maybe<BiogenLincConfig>;
  biogenLogo?: Maybe<Scalars['String']>;
  buttonColor?: Maybe<Scalars['String']>;
  coOrganizers?: Maybe<Array<OktaPublicUserInfo>>;
  collectParticipantPhoneNumber?: Maybe<Scalars['Boolean']>;
  collectPhoneNumberMandatory?: Maybe<Scalars['Boolean']>;
  collectPhoneNumberUserConsent?: Maybe<Scalars['Boolean']>;
  collectTextMessageUserConsent?: Maybe<Scalars['Boolean']>;
  confirmationEmailMayaId?: Maybe<Scalars['String']>;
  confirmationEmailUTM?: Maybe<Scalars['String']>;
  cventConfig?: Maybe<CventConfig>;
  cxSurveyActivation?: Maybe<Scalars['Boolean']>;
  cxSurveyId?: Maybe<Scalars['String']>;
  cxSurveyMayaId?: Maybe<Scalars['String']>;
  cxSurveyUTM?: Maybe<Scalars['String']>;
  cxSurveyUseCaseCategory?: Maybe<Scalars['String']>;
  cxSurveyUseCaseName?: Maybe<Scalars['String']>;
  disclaimerText?: Maybe<Scalars['String']>;
  emailFooterDisclaimerText?: Maybe<Scalars['String']>;
  emailFooterNote?: Maybe<Scalars['String']>;
  emailFooterNoteEmailTypes?: Maybe<Array<Scalars['String']>>;
  emailHeaderNote?: Maybe<Scalars['String']>;
  emailHeaderNoteEmailTypes?: Maybe<Array<Scalars['String']>>;
  emailPrcCode?: Maybe<Scalars['String']>;
  emailsScreenshotPdfUrl?: Maybe<Scalars['String']>;
  enableZoomStreaming?: Maybe<Scalars['Boolean']>;
  eventLocation?: Maybe<Scalars['String']>;
  eventPresence: Presence;
  footerDisclaimerText?: Maybe<Scalars['String']>;
  invitationEmailMayaId?: Maybe<Scalars['String']>;
  invitationEmailUTM?: Maybe<Scalars['String']>;
  invitationSent?: Maybe<Scalars['Boolean']>;
  isRestrictedAccess?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Metadata>;
  participant?: Maybe<Participant>;
  participants?: Maybe<Array<Participant>>;
  participantsSupportPhoneNumber?: Maybe<Scalars['String']>;
  physicalCxSurveyActivation?: Maybe<Scalars['Boolean']>;
  physicalCxSurveyId?: Maybe<Scalars['String']>;
  physicalCxSurveyUseCaseCategory?: Maybe<Scalars['String']>;
  physicalCxSurveyUseCaseName?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  productInformationButtonLabel?: Maybe<Scalars['String']>;
  productInformationButtonLink?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  recordingOptinLabel?: Maybe<Scalars['String']>;
  registrationEndDate?: Maybe<Scalars['DateTime']>;
  reminder1hMayaId?: Maybe<Scalars['String']>;
  reminder1hUTM?: Maybe<Scalars['String']>;
  reminder12hMayaId?: Maybe<Scalars['String']>;
  reminder12hUTM?: Maybe<Scalars['String']>;
  reminder24hMayaId?: Maybe<Scalars['String']>;
  reminder24hUTM?: Maybe<Scalars['String']>;
  reminder48hMayaId?: Maybe<Scalars['String']>;
  reminder48hUTM?: Maybe<Scalars['String']>;
  reminderNotificationEmails?: Maybe<Array<Scalars['String']>>;
  reminderNotificationPhoneNumbers?: Maybe<Array<Scalars['String']>>;
  restrictedDescription?: Maybe<Scalars['String']>;
  screenshotPdfUrl?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['Float']>;
  session?: Maybe<Session>;
  showFooterNavItems?: Maybe<Scalars['Boolean']>;
  showNavigationBarActions?: Maybe<Scalars['Boolean']>;
  showNavigationBarElements?: Maybe<Scalars['Boolean']>;
  speakerSupportPhoneNumber?: Maybe<Scalars['String']>;
  speakers?: Maybe<Array<Speaker>>;
  teamContactCheckbox?: Maybe<Scalars['Boolean']>;
  teamContactDescription?: Maybe<Scalars['String']>;
  teamContactEmail?: Maybe<Scalars['String']>;
  testimonials?: Maybe<Array<Testimonial>>;
  userGroups?: Maybe<Array<Scalars['String']>>;
};

export type HybridEventInput = {
  agenda?: InputMaybe<Array<AgendaEntryInput>>;
  agendaFileDescription?: InputMaybe<Scalars['String']>;
  agendaFileTitle?: InputMaybe<Scalars['String']>;
  assets?: InputMaybe<AssetsByTargetInput>;
  biogenLincConfig?: InputMaybe<BiogenLincConfigInput>;
  biogenLogo?: InputMaybe<Scalars['String']>;
  buttonColor?: InputMaybe<Scalars['String']>;
  coOrganizers?: InputMaybe<Array<OktaPublicUserInfoInput>>;
  collectParticipantPhoneNumber?: InputMaybe<Scalars['Boolean']>;
  collectPhoneNumberMandatory?: InputMaybe<Scalars['Boolean']>;
  collectPhoneNumberUserConsent?: InputMaybe<Scalars['Boolean']>;
  collectTextMessageUserConsent?: InputMaybe<Scalars['Boolean']>;
  confirmationEmailMayaId?: InputMaybe<Scalars['String']>;
  confirmationEmailUTM?: InputMaybe<Scalars['String']>;
  contentfulUrl?: InputMaybe<Scalars['String']>;
  cventConfig?: InputMaybe<CventConfigInput>;
  cxSurveyActivation?: InputMaybe<Scalars['Boolean']>;
  cxSurveyId?: InputMaybe<Scalars['String']>;
  cxSurveyMayaId?: InputMaybe<Scalars['String']>;
  cxSurveyUTM?: InputMaybe<Scalars['String']>;
  cxSurveyUseCaseCategory?: InputMaybe<Scalars['String']>;
  cxSurveyUseCaseName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimerText?: InputMaybe<Scalars['String']>;
  emailFooterDisclaimerText?: InputMaybe<Scalars['String']>;
  emailFooterNote?: InputMaybe<Scalars['String']>;
  emailFooterNoteEmailTypes?: InputMaybe<Array<Scalars['String']>>;
  emailHeaderNote?: InputMaybe<Scalars['String']>;
  emailHeaderNoteEmailTypes?: InputMaybe<Array<Scalars['String']>>;
  emailPrcCode?: InputMaybe<Scalars['String']>;
  emailsScreenshotPdfUrl?: InputMaybe<Scalars['String']>;
  enableZoomStreaming?: InputMaybe<Scalars['Boolean']>;
  eventLocation?: InputMaybe<Scalars['String']>;
  footerDisclaimerText?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  invitationEmailMayaId?: InputMaybe<Scalars['String']>;
  invitationEmailUTM?: InputMaybe<Scalars['String']>;
  isRestrictedAccess?: InputMaybe<Scalars['Boolean']>;
  isRsvpType?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  participantsSupportPhoneNumber?: InputMaybe<Scalars['String']>;
  physicalCxSurveyActivation?: InputMaybe<Scalars['Boolean']>;
  physicalCxSurveyId?: InputMaybe<Scalars['String']>;
  physicalCxSurveyUseCaseCategory?: InputMaybe<Scalars['String']>;
  physicalCxSurveyUseCaseName?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  productInformationButtonLabel?: InputMaybe<Scalars['String']>;
  productInformationButtonLink?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<ProviderInput>;
  recordingOptinLabel?: InputMaybe<Scalars['String']>;
  registrationEndDate?: InputMaybe<Scalars['DateTime']>;
  reminder1hMayaId?: InputMaybe<Scalars['String']>;
  reminder1hUTM?: InputMaybe<Scalars['String']>;
  reminder12hMayaId?: InputMaybe<Scalars['String']>;
  reminder12hUTM?: InputMaybe<Scalars['String']>;
  reminder24hMayaId?: InputMaybe<Scalars['String']>;
  reminder24hUTM?: InputMaybe<Scalars['String']>;
  reminder48hMayaId?: InputMaybe<Scalars['String']>;
  reminder48hUTM?: InputMaybe<Scalars['String']>;
  reminderNotificationEmails?: InputMaybe<Array<Scalars['String']>>;
  reminderNotificationPhoneNumbers?: InputMaybe<Array<Scalars['String']>>;
  restrictedDescription?: InputMaybe<Scalars['String']>;
  screenshotPdfUrl?: InputMaybe<Scalars['String']>;
  seats?: InputMaybe<Scalars['Float']>;
  showFooterNavItems?: InputMaybe<Scalars['Boolean']>;
  showNavigationBarActions?: InputMaybe<Scalars['Boolean']>;
  showNavigationBarElements?: InputMaybe<Scalars['Boolean']>;
  speakerSupportPhoneNumber?: InputMaybe<Scalars['String']>;
  speakers?: InputMaybe<Array<Scalars['String']>>;
  teamContactCheckbox?: InputMaybe<Scalars['Boolean']>;
  teamContactDescription?: InputMaybe<Scalars['String']>;
  teamContactEmail?: InputMaybe<Scalars['String']>;
  testimonials?: InputMaybe<Array<TestimonialInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type InsciteFieldData = {
  __typename?: 'InsciteFieldData';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Journey = {
  __typename?: 'Journey';
  arrivalDate: Scalars['DateTime'];
  arrivalPlace: Scalars['String'];
  assets: Array<Scalars['String']>;
  departureDate: Scalars['DateTime'];
  departurePlace: Scalars['String'];
  order: Scalars['Float'];
  transportType: TransportType;
};

export type JourneyInput = {
  arrivalDate: Scalars['DateTime'];
  arrivalPlace: Scalars['String'];
  assets: Array<Scalars['String']>;
  departureDate: Scalars['DateTime'];
  departurePlace: Scalars['String'];
  order: Scalars['Float'];
  transportType: TransportType;
};

export type Location = {
  __typename?: 'Location';
  city: Scalars['String'];
  country: Scalars['String'];
  number: Scalars['String'];
  placeName: Scalars['String'];
  street: Scalars['String'];
};

export type LocationInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  number: Scalars['String'];
  placeName: Scalars['String'];
  street: Scalars['String'];
};

export enum Market {
  ar = 'ar',
  at = 'at',
  au = 'au',
  be = 'be',
  br = 'br',
  ca = 'ca',
  ch = 'ch',
  com = 'com',
  de = 'de',
  dk = 'dk',
  es = 'es',
  fr = 'fr',
  gb = 'gb',
  gulf = 'gulf',
  ie = 'ie',
  jp = 'jp',
  mx = 'mx',
  nl = 'nl',
  nordics = 'nordics',
  pl = 'pl',
  pt = 'pt',
  uk = 'uk',
  us = 'us'
}

export type Metadata = {
  __typename?: 'Metadata';
  contentType?: Maybe<Array<Scalars['String']>>;
  eventType?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  product?: Maybe<Array<Scalars['String']>>;
  therapyArea?: Maybe<Array<Scalars['String']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptParticipant: Participant;
  bookSlot?: Maybe<Scalars['Boolean']>;
  cancelParticipantRegistration?: Maybe<Participant>;
  changeParticipantChannel: Scalars['Boolean'];
  checkInParticipant: Participant;
  clearParticipantRegistrationStatus: Participant;
  createEvent: Event;
  createOrUpdateSpeaker: Speaker;
  createOrUpdateUserData: UserData;
  createParticipantsFromCSV: Array<Participant>;
  createProgram: Program;
  declineParticipant: Scalars['Boolean'];
  deleteSpeaker: Speaker;
  emptyS3Directory?: Maybe<Scalars['Boolean']>;
  inviteParticipant: Scalars['Boolean'];
  publishHybridEvent: Event;
  publishOnlineEvent: Event;
  publishPhysicalEvent: Event;
  registerParticipant: Scalars['Boolean'];
  registrationEmailCheck: Scalars['String'];
  sendEventInvitation: Scalars['Boolean'];
  syncAllParticipants: Array<Participant>;
  syncInsciteDocument?: Maybe<SubmitDocumentStatus>;
  syncParticipant: Participant;
  testEvent: Event;
  unpublishOnlineEvent: EventStatus;
  updateHybridEvent: Event;
  updateOnlineEvent: Event;
  updatePhysicalEvent: Event;
  updateScreenshotPdfUrl?: Maybe<Scalars['String']>;
  updateZoomData?: Maybe<Provider>;
  uploadFiles: Array<AssetsEvent>;
};


export type MutationAcceptParticipantArgs = {
  participant: RegisterParticipantInput;
};


export type MutationBookSlotArgs = {
  eventId: Scalars['String'];
  session: SessionInput;
};


export type MutationCancelParticipantRegistrationArgs = {
  participant: RegisterParticipantInput;
};


export type MutationChangeParticipantChannelArgs = {
  participant: RegisterParticipantInput;
};


export type MutationCheckInParticipantArgs = {
  participant: RegisterParticipantInput;
};


export type MutationClearParticipantRegistrationStatusArgs = {
  email: Scalars['String'];
  eventId: Scalars['String'];
  mdmId: Scalars['String'];
  status: Scalars['String'];
};


export type MutationCreateEventArgs = {
  event: EventInput;
};


export type MutationCreateOrUpdateSpeakerArgs = {
  speaker: SpeakerInput;
};


export type MutationCreateOrUpdateUserDataArgs = {
  userData: UserDataInput;
};


export type MutationCreateParticipantsFromCsvArgs = {
  eventId: Scalars['String'];
  file: Scalars['Upload'];
  fileDataInput: FileDataInput;
  isRestricted?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateProgramArgs = {
  program: ProgramInput;
};


export type MutationDeclineParticipantArgs = {
  participant: RegisterParticipantInput;
};


export type MutationDeleteSpeakerArgs = {
  speaker: SpeakerInput;
};


export type MutationEmptyS3DirectoryArgs = {
  prefix: Scalars['String'];
};


export type MutationInviteParticipantArgs = {
  participant: RegisterParticipantInput;
};


export type MutationPublishHybridEventArgs = {
  hybridEventInput: HybridEventInput;
};


export type MutationPublishOnlineEventArgs = {
  onlineEventInput: OnlineEventInput;
};


export type MutationPublishPhysicalEventArgs = {
  physicalEventInput: PhysicalEventInput;
};


export type MutationRegisterParticipantArgs = {
  participant: RegisterParticipantInput;
  timeZone?: InputMaybe<Scalars['String']>;
};


export type MutationRegistrationEmailCheckArgs = {
  consentChecked: Scalars['Boolean'];
  email: Scalars['String'];
  eventId: Scalars['String'];
  joiningChannel?: InputMaybe<Scalars['String']>;
  textMessageConsentChecked: Scalars['Boolean'];
  timeZone?: InputMaybe<Scalars['String']>;
};


export type MutationSendEventInvitationArgs = {
  eventId: Scalars['String'];
};


export type MutationSyncAllParticipantsArgs = {
  eventId: Scalars['String'];
};


export type MutationSyncInsciteDocumentArgs = {
  document: VeevaDocumentInput;
  pdfUrl: Scalars['String'];
  shouldUpdateFile: Scalars['Boolean'];
};


export type MutationSyncParticipantArgs = {
  participant: RegisterParticipantInput;
};


export type MutationTestEventArgs = {
  eventId: Scalars['String'];
  testUser: TestUserInput;
};


export type MutationUnpublishOnlineEventArgs = {
  eventId: Scalars['String'];
};


export type MutationUpdateHybridEventArgs = {
  hybridEventFormat?: InputMaybe<Format>;
  hybridEventInput: HybridEventInput;
};


export type MutationUpdateOnlineEventArgs = {
  onlineEventFormat?: InputMaybe<Format>;
  onlineEventInput: OnlineEventInput;
};


export type MutationUpdatePhysicalEventArgs = {
  physicalEventFormat?: InputMaybe<Format>;
  physicalEventInput: PhysicalEventInput;
};


export type MutationUpdateScreenshotPdfUrlArgs = {
  eventId: Scalars['String'];
  pdfUrl: Scalars['String'];
  screenshotPdfType: Scalars['String'];
};


export type MutationUpdateZoomDataArgs = {
  eventId: Scalars['String'];
};


export type MutationUploadFilesArgs = {
  assetsArePublic?: InputMaybe<Scalars['Boolean']>;
  files: Array<Scalars['Upload']>;
  filesDataInput: Array<FileDataInput>;
  prefix: Scalars['String'];
};

export type OktaPublicUserInfo = {
  __typename?: 'OktaPublicUserInfo';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type OktaPublicUserInfoInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type OktaUserInfo = {
  __typename?: 'OktaUserInfo';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type OktaUserInfoInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type OnlineEvent = {
  __typename?: 'OnlineEvent';
  agenda?: Maybe<Array<AgendaEntry>>;
  agendaFileDescription?: Maybe<Scalars['String']>;
  agendaFileTitle?: Maybe<Scalars['String']>;
  assets?: Maybe<AssetsByTarget>;
  biogenLincConfig?: Maybe<BiogenLincConfig>;
  biogenLogo?: Maybe<Scalars['String']>;
  buttonColor?: Maybe<Scalars['String']>;
  coOrganizers?: Maybe<Array<OktaPublicUserInfo>>;
  collectParticipantPhoneNumber?: Maybe<Scalars['Boolean']>;
  collectPhoneNumberMandatory?: Maybe<Scalars['Boolean']>;
  collectPhoneNumberUserConsent?: Maybe<Scalars['Boolean']>;
  collectTextMessageUserConsent?: Maybe<Scalars['Boolean']>;
  confirmationEmailMayaId?: Maybe<Scalars['String']>;
  confirmationEmailUTM?: Maybe<Scalars['String']>;
  cventConfig?: Maybe<CventConfig>;
  cxSurveyActivation?: Maybe<Scalars['Boolean']>;
  cxSurveyId?: Maybe<Scalars['String']>;
  cxSurveyMayaId?: Maybe<Scalars['String']>;
  cxSurveyUTM?: Maybe<Scalars['String']>;
  cxSurveyUseCaseCategory?: Maybe<Scalars['String']>;
  cxSurveyUseCaseName?: Maybe<Scalars['String']>;
  disclaimerText?: Maybe<Scalars['String']>;
  emailFooterDisclaimerText?: Maybe<Scalars['String']>;
  emailFooterNote?: Maybe<Scalars['String']>;
  emailFooterNoteEmailTypes?: Maybe<Array<Scalars['String']>>;
  emailHeaderNote?: Maybe<Scalars['String']>;
  emailHeaderNoteEmailTypes?: Maybe<Array<Scalars['String']>>;
  emailPrcCode?: Maybe<Scalars['String']>;
  emailsScreenshotPdfUrl?: Maybe<Scalars['String']>;
  enableZoomStreaming?: Maybe<Scalars['Boolean']>;
  eventPresence: Presence;
  footerDisclaimerText?: Maybe<Scalars['String']>;
  invitationEmailMayaId?: Maybe<Scalars['String']>;
  invitationEmailUTM?: Maybe<Scalars['String']>;
  invitationSent?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Metadata>;
  participant?: Maybe<Participant>;
  participants?: Maybe<Array<Participant>>;
  participantsSupportPhoneNumber?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  productInformationButtonLabel?: Maybe<Scalars['String']>;
  productInformationButtonLink?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  recordingOptinLabel?: Maybe<Scalars['String']>;
  reminder1hMayaId?: Maybe<Scalars['String']>;
  reminder1hUTM?: Maybe<Scalars['String']>;
  reminder12hMayaId?: Maybe<Scalars['String']>;
  reminder12hUTM?: Maybe<Scalars['String']>;
  reminder24hMayaId?: Maybe<Scalars['String']>;
  reminder24hUTM?: Maybe<Scalars['String']>;
  reminder48hMayaId?: Maybe<Scalars['String']>;
  reminder48hUTM?: Maybe<Scalars['String']>;
  reminderNotificationEmails?: Maybe<Array<Scalars['String']>>;
  reminderNotificationPhoneNumbers?: Maybe<Array<Scalars['String']>>;
  screenshotPdfUrl?: Maybe<Scalars['String']>;
  session?: Maybe<Session>;
  showFooterNavItems?: Maybe<Scalars['Boolean']>;
  showNavigationBarActions?: Maybe<Scalars['Boolean']>;
  showNavigationBarElements?: Maybe<Scalars['Boolean']>;
  speakerSupportPhoneNumber?: Maybe<Scalars['String']>;
  speakers?: Maybe<Array<Speaker>>;
  teamContactCheckbox?: Maybe<Scalars['Boolean']>;
  teamContactDescription?: Maybe<Scalars['String']>;
  teamContactEmail?: Maybe<Scalars['String']>;
  testimonials?: Maybe<Array<Testimonial>>;
  userGroups?: Maybe<Array<Scalars['String']>>;
};

export type OnlineEventInput = {
  agenda?: InputMaybe<Array<AgendaEntryInput>>;
  agendaFileDescription?: InputMaybe<Scalars['String']>;
  agendaFileTitle?: InputMaybe<Scalars['String']>;
  assets?: InputMaybe<AssetsByTargetInput>;
  biogenLincConfig?: InputMaybe<BiogenLincConfigInput>;
  biogenLogo?: InputMaybe<Scalars['String']>;
  buttonColor?: InputMaybe<Scalars['String']>;
  coOrganizers?: InputMaybe<Array<OktaPublicUserInfoInput>>;
  collectParticipantPhoneNumber?: InputMaybe<Scalars['Boolean']>;
  collectPhoneNumberMandatory?: InputMaybe<Scalars['Boolean']>;
  collectPhoneNumberUserConsent?: InputMaybe<Scalars['Boolean']>;
  collectTextMessageUserConsent?: InputMaybe<Scalars['Boolean']>;
  confirmationEmailMayaId?: InputMaybe<Scalars['String']>;
  confirmationEmailUTM?: InputMaybe<Scalars['String']>;
  contentfulUrl?: InputMaybe<Scalars['String']>;
  cventConfig?: InputMaybe<CventConfigInput>;
  cxSurveyActivation?: InputMaybe<Scalars['Boolean']>;
  cxSurveyId?: InputMaybe<Scalars['String']>;
  cxSurveyMayaId?: InputMaybe<Scalars['String']>;
  cxSurveyUTM?: InputMaybe<Scalars['String']>;
  cxSurveyUseCaseCategory?: InputMaybe<Scalars['String']>;
  cxSurveyUseCaseName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimerText?: InputMaybe<Scalars['String']>;
  emailFooterDisclaimerText?: InputMaybe<Scalars['String']>;
  emailFooterNote?: InputMaybe<Scalars['String']>;
  emailFooterNoteEmailTypes?: InputMaybe<Array<Scalars['String']>>;
  emailHeaderNote?: InputMaybe<Scalars['String']>;
  emailHeaderNoteEmailTypes?: InputMaybe<Array<Scalars['String']>>;
  emailPrcCode?: InputMaybe<Scalars['String']>;
  emailsScreenshotPdfUrl?: InputMaybe<Scalars['String']>;
  enableZoomStreaming?: InputMaybe<Scalars['Boolean']>;
  footerDisclaimerText?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  invitationEmailMayaId?: InputMaybe<Scalars['String']>;
  invitationEmailUTM?: InputMaybe<Scalars['String']>;
  isRsvpType?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  participantsSupportPhoneNumber?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  productInformationButtonLabel?: InputMaybe<Scalars['String']>;
  productInformationButtonLink?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<ProviderInput>;
  recordingOptinLabel?: InputMaybe<Scalars['String']>;
  reminder1hMayaId?: InputMaybe<Scalars['String']>;
  reminder1hUTM?: InputMaybe<Scalars['String']>;
  reminder12hMayaId?: InputMaybe<Scalars['String']>;
  reminder12hUTM?: InputMaybe<Scalars['String']>;
  reminder24hMayaId?: InputMaybe<Scalars['String']>;
  reminder24hUTM?: InputMaybe<Scalars['String']>;
  reminder48hMayaId?: InputMaybe<Scalars['String']>;
  reminder48hUTM?: InputMaybe<Scalars['String']>;
  reminderNotificationEmails?: InputMaybe<Array<Scalars['String']>>;
  reminderNotificationPhoneNumbers?: InputMaybe<Array<Scalars['String']>>;
  screenshotPdfUrl?: InputMaybe<Scalars['String']>;
  showFooterNavItems?: InputMaybe<Scalars['Boolean']>;
  showNavigationBarActions?: InputMaybe<Scalars['Boolean']>;
  showNavigationBarElements?: InputMaybe<Scalars['Boolean']>;
  speakerSupportPhoneNumber?: InputMaybe<Scalars['String']>;
  speakers?: InputMaybe<Array<Scalars['String']>>;
  teamContactCheckbox?: InputMaybe<Scalars['Boolean']>;
  teamContactDescription?: InputMaybe<Scalars['String']>;
  teamContactEmail?: InputMaybe<Scalars['String']>;
  testimonials?: InputMaybe<Array<TestimonialInput>>;
  title?: InputMaybe<Scalars['String']>;
  userGroups?: InputMaybe<Array<Scalars['String']>>;
};

export enum OriginMarket {
  AE = 'AE',
  AT = 'AT',
  AU = 'AU',
  BE = 'BE',
  BH = 'BH',
  BR = 'BR',
  CA = 'CA',
  CH = 'CH',
  DE = 'DE',
  DK = 'DK',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  IE = 'IE',
  JP = 'JP',
  KW = 'KW',
  MX = 'MX',
  NL = 'NL',
  NO = 'NO',
  OM = 'OM',
  PL = 'PL',
  PT = 'PT',
  QA = 'QA',
  SA = 'SA',
  SE = 'SE',
  UK = 'UK',
  US = 'US'
}

export type Participant = {
  __typename?: 'Participant';
  accommodation?: Maybe<Accommodation>;
  accountId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  assets?: Maybe<Array<Scalars['String']>>;
  attendedZoom?: Maybe<Scalars['Boolean']>;
  attendedZoomDuration?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  collectedPhoneNumber?: Maybe<Scalars['String']>;
  consentCollection?: Maybe<Scalars['Boolean']>;
  cxEmailSent?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  externalEventParticipantStatus?: Maybe<Scalars['String']>;
  externalJoinLink?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  guestCountryCode?: Maybe<Scalars['String']>;
  institution?: Maybe<Scalars['String']>;
  invitationEmailSent?: Maybe<Scalars['Boolean']>;
  joiningChannel?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastVeevaSync?: Maybe<Scalars['DateTime']>;
  lastVeevaSyncError?: Maybe<Scalars['String']>;
  mdmId?: Maybe<Scalars['String']>;
  nationalId?: Maybe<Scalars['String']>;
  professionalId?: Maybe<Scalars['String']>;
  registrationEmailSent?: Maybe<Scalars['Boolean']>;
  registrationLocale?: Maybe<Scalars['String']>;
  registrationStatusHistory: RegistrationStatusHistory;
  reminder1h?: Maybe<Scalars['Boolean']>;
  reminder1week?: Maybe<Scalars['Boolean']>;
  reminder12h?: Maybe<Scalars['Boolean']>;
  reminder24h?: Maybe<Scalars['Boolean']>;
  reminder48h?: Maybe<Scalars['Boolean']>;
  reminderSMS?: Maybe<Scalars['Boolean']>;
  restricted?: Maybe<Scalars['Boolean']>;
  selectedMultiLanguage?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  surveyLink?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  textMessageConsentCollection?: Maybe<Scalars['Boolean']>;
  timeZone?: Maybe<Scalars['String']>;
  transportationRequests?: Maybe<Array<TransportationRequest>>;
  uniqueLink?: Maybe<Scalars['String']>;
  veevaSyncStatus?: Maybe<Scalars['String']>;
  walkInType?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type PersonalInfosVisibility = {
  __typename?: 'PersonalInfosVisibility';
  eventId?: Maybe<Scalars['String']>;
  showAffiliation?: Maybe<Scalars['Boolean']>;
  showEmail?: Maybe<Scalars['Boolean']>;
  showImage?: Maybe<Scalars['Boolean']>;
  showSpecialty?: Maybe<Scalars['Boolean']>;
  showTitle?: Maybe<Scalars['Boolean']>;
};

export type PersonalInfosVisibilityInput = {
  eventId?: InputMaybe<Scalars['String']>;
  showAffiliation?: InputMaybe<Scalars['Boolean']>;
  showEmail?: InputMaybe<Scalars['Boolean']>;
  showImage?: InputMaybe<Scalars['Boolean']>;
  showSpecialty?: InputMaybe<Scalars['Boolean']>;
  showTitle?: InputMaybe<Scalars['Boolean']>;
};

export type PhysicalEvent = {
  __typename?: 'PhysicalEvent';
  agenda?: Maybe<Array<AgendaEntry>>;
  agendaFileDescription?: Maybe<Scalars['String']>;
  agendaFileTitle?: Maybe<Scalars['String']>;
  assets?: Maybe<AssetsByTarget>;
  availableSeats: Scalars['Float'];
  biogenLincConfig?: Maybe<BiogenLincConfig>;
  biogenLogo?: Maybe<Scalars['String']>;
  buttonColor?: Maybe<Scalars['String']>;
  coOrganizers?: Maybe<Array<OktaPublicUserInfo>>;
  collectParticipantPhoneNumber?: Maybe<Scalars['Boolean']>;
  collectPhoneNumberMandatory?: Maybe<Scalars['Boolean']>;
  collectPhoneNumberUserConsent?: Maybe<Scalars['Boolean']>;
  collectTextMessageUserConsent?: Maybe<Scalars['Boolean']>;
  confirmationEmailMayaId?: Maybe<Scalars['String']>;
  confirmationEmailUTM?: Maybe<Scalars['String']>;
  cventConfig?: Maybe<CventConfig>;
  cxSurveyActivation?: Maybe<Scalars['Boolean']>;
  cxSurveyId?: Maybe<Scalars['String']>;
  cxSurveyMayaId?: Maybe<Scalars['String']>;
  cxSurveyUTM?: Maybe<Scalars['String']>;
  cxSurveyUseCaseCategory?: Maybe<Scalars['String']>;
  cxSurveyUseCaseName?: Maybe<Scalars['String']>;
  disclaimerText?: Maybe<Scalars['String']>;
  emailFooterDisclaimerText?: Maybe<Scalars['String']>;
  emailFooterNote?: Maybe<Scalars['String']>;
  emailFooterNoteEmailTypes?: Maybe<Array<Scalars['String']>>;
  emailHeaderNote?: Maybe<Scalars['String']>;
  emailHeaderNoteEmailTypes?: Maybe<Array<Scalars['String']>>;
  emailPrcCode?: Maybe<Scalars['String']>;
  emailsScreenshotPdfUrl?: Maybe<Scalars['String']>;
  enableDeclineModal?: Maybe<Scalars['Boolean']>;
  eventLocation?: Maybe<Scalars['String']>;
  eventPresence: Presence;
  footerDisclaimerText?: Maybe<Scalars['String']>;
  invitationEmailMayaId?: Maybe<Scalars['String']>;
  invitationEmailUTM?: Maybe<Scalars['String']>;
  invitationSent?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Metadata>;
  participant?: Maybe<Participant>;
  participants?: Maybe<Array<Participant>>;
  participantsSupportPhoneNumber?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  productInformationButtonLabel?: Maybe<Scalars['String']>;
  productInformationButtonLink?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  registrationEndDate?: Maybe<Scalars['DateTime']>;
  reminder1hMayaId?: Maybe<Scalars['String']>;
  reminder1hUTM?: Maybe<Scalars['String']>;
  reminder12hMayaId?: Maybe<Scalars['String']>;
  reminder12hUTM?: Maybe<Scalars['String']>;
  reminder24hMayaId?: Maybe<Scalars['String']>;
  reminder24hUTM?: Maybe<Scalars['String']>;
  reminder48hMayaId?: Maybe<Scalars['String']>;
  reminder48hUTM?: Maybe<Scalars['String']>;
  reminderNotificationEmails?: Maybe<Array<Scalars['String']>>;
  reminderNotificationPhoneNumbers?: Maybe<Array<Scalars['String']>>;
  screenshotPdfUrl?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['Float']>;
  session?: Maybe<Session>;
  showFooterNavItems?: Maybe<Scalars['Boolean']>;
  showNavigationBarActions?: Maybe<Scalars['Boolean']>;
  showNavigationBarElements?: Maybe<Scalars['Boolean']>;
  speakerSupportPhoneNumber?: Maybe<Scalars['String']>;
  speakers?: Maybe<Array<Speaker>>;
  teamContactCheckbox?: Maybe<Scalars['Boolean']>;
  teamContactDescription?: Maybe<Scalars['String']>;
  teamContactEmail?: Maybe<Scalars['String']>;
  testimonials?: Maybe<Array<Testimonial>>;
  userGroups?: Maybe<Array<Scalars['String']>>;
};

export type PhysicalEventInput = {
  agenda?: InputMaybe<Array<AgendaEntryInput>>;
  agendaFileDescription?: InputMaybe<Scalars['String']>;
  agendaFileTitle?: InputMaybe<Scalars['String']>;
  assets?: InputMaybe<AssetsByTargetInput>;
  biogenLincConfig?: InputMaybe<BiogenLincConfigInput>;
  biogenLogo?: InputMaybe<Scalars['String']>;
  buttonColor?: InputMaybe<Scalars['String']>;
  coOrganizers?: InputMaybe<Array<OktaPublicUserInfoInput>>;
  collectParticipantPhoneNumber?: InputMaybe<Scalars['Boolean']>;
  collectPhoneNumberMandatory?: InputMaybe<Scalars['Boolean']>;
  collectPhoneNumberUserConsent?: InputMaybe<Scalars['Boolean']>;
  collectTextMessageUserConsent?: InputMaybe<Scalars['Boolean']>;
  confirmationEmailMayaId?: InputMaybe<Scalars['String']>;
  confirmationEmailUTM?: InputMaybe<Scalars['String']>;
  contentfulUrl?: InputMaybe<Scalars['String']>;
  cventConfig?: InputMaybe<CventConfigInput>;
  cxSurveyActivation?: InputMaybe<Scalars['Boolean']>;
  cxSurveyId?: InputMaybe<Scalars['String']>;
  cxSurveyMayaId?: InputMaybe<Scalars['String']>;
  cxSurveyUTM?: InputMaybe<Scalars['String']>;
  cxSurveyUseCaseCategory?: InputMaybe<Scalars['String']>;
  cxSurveyUseCaseName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimerText?: InputMaybe<Scalars['String']>;
  emailFooterDisclaimerText?: InputMaybe<Scalars['String']>;
  emailFooterNote?: InputMaybe<Scalars['String']>;
  emailFooterNoteEmailTypes?: InputMaybe<Array<Scalars['String']>>;
  emailHeaderNote?: InputMaybe<Scalars['String']>;
  emailHeaderNoteEmailTypes?: InputMaybe<Array<Scalars['String']>>;
  emailPrcCode?: InputMaybe<Scalars['String']>;
  emailsScreenshotPdfUrl?: InputMaybe<Scalars['String']>;
  enableDeclineModal?: InputMaybe<Scalars['Boolean']>;
  eventLocation?: InputMaybe<Scalars['String']>;
  footerDisclaimerText?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  invitationEmailMayaId?: InputMaybe<Scalars['String']>;
  invitationEmailUTM?: InputMaybe<Scalars['String']>;
  isRsvpType?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  participantsSupportPhoneNumber?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  productInformationButtonLabel?: InputMaybe<Scalars['String']>;
  productInformationButtonLink?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  registrationEndDate?: InputMaybe<Scalars['DateTime']>;
  reminder1hMayaId?: InputMaybe<Scalars['String']>;
  reminder1hUTM?: InputMaybe<Scalars['String']>;
  reminder12hMayaId?: InputMaybe<Scalars['String']>;
  reminder12hUTM?: InputMaybe<Scalars['String']>;
  reminder24hMayaId?: InputMaybe<Scalars['String']>;
  reminder24hUTM?: InputMaybe<Scalars['String']>;
  reminder48hMayaId?: InputMaybe<Scalars['String']>;
  reminder48hUTM?: InputMaybe<Scalars['String']>;
  reminderNotificationEmails?: InputMaybe<Array<Scalars['String']>>;
  reminderNotificationPhoneNumbers?: InputMaybe<Array<Scalars['String']>>;
  screenshotPdfUrl?: InputMaybe<Scalars['String']>;
  seats?: InputMaybe<Scalars['Float']>;
  session?: InputMaybe<SessionInput>;
  showFooterNavItems?: InputMaybe<Scalars['Boolean']>;
  showNavigationBarActions?: InputMaybe<Scalars['Boolean']>;
  showNavigationBarElements?: InputMaybe<Scalars['Boolean']>;
  speakerSupportPhoneNumber?: InputMaybe<Scalars['String']>;
  speakers?: InputMaybe<Array<Scalars['String']>>;
  teamContactCheckbox?: InputMaybe<Scalars['Boolean']>;
  teamContactDescription?: InputMaybe<Scalars['String']>;
  teamContactEmail?: InputMaybe<Scalars['String']>;
  testimonials?: InputMaybe<Array<TestimonialInput>>;
  title?: InputMaybe<Scalars['String']>;
  userGroups?: InputMaybe<Array<Scalars['String']>>;
};

export type PicklistFieldData = {
  __typename?: 'PicklistFieldData';
  label: Scalars['String'];
  name: Scalars['String'];
};

export enum Presence {
  hybrid = 'hybrid',
  online = 'online',
  physical = 'physical'
}

export type Profile = {
  __typename?: 'Profile';
  default?: Maybe<ProfileByMarket>;
};

export type ProfileByMarket = {
  __typename?: 'ProfileByMarket';
  affiliation?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  _id: Scalars['String'];
  programName?: Maybe<Scalars['String']>;
};

export type ProgramInput = {
  programName?: InputMaybe<Scalars['String']>;
};

export type Provider = {
  __typename?: 'Provider';
  endWithGap?: Maybe<Scalars['DateTime']>;
  host: HostProvider;
  licenseId?: Maybe<Scalars['String']>;
  organizerUrl?: Maybe<Scalars['String']>;
  participantUrl?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  providedId?: Maybe<Scalars['String']>;
  startWithGap?: Maybe<Scalars['DateTime']>;
};

export type ProviderInput = {
  endWithGap?: InputMaybe<Scalars['DateTime']>;
  host: HostProvider;
  licenseId?: InputMaybe<Scalars['String']>;
  organizerUrl?: InputMaybe<Scalars['String']>;
  participantUrl?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  providedId?: InputMaybe<Scalars['String']>;
  startWithGap?: InputMaybe<Scalars['DateTime']>;
};

export type Query = {
  __typename?: 'Query';
  exportEventParticipantData: StreamableBuffer;
  findSpeakers: FindSpeakerResponse;
  getEmailCompiledHtml?: Maybe<Array<Scalars['String']>>;
  getEventById?: Maybe<Event>;
  getEvents: Array<Event>;
  getFullPrivateEventById?: Maybe<Event>;
  getFullPrivateEvents: Array<Event>;
  getFullPrivateEventsCount: Scalars['Float'];
  getGlobalSurveyData: SurveyInfos;
  getParticipantByMdmId: Participant;
  getPossibleOrganizers: Array<OktaPublicUserInfo>;
  getPrograms: Array<Program>;
  getRsvpEvent?: Maybe<Event>;
  getS3File?: Maybe<Scalars['String']>;
  getScreenshots: Scalars['String'];
  getSecret?: Maybe<Scalars['String']>;
  getSpeakerById: Speaker;
  getSurveyLink?: Maybe<Scalars['String']>;
  getSurveysId: Array<SurveyInfos>;
  getUserData?: Maybe<UserData>;
  getVeevaInsciteDocumentType?: Maybe<DocumentTypeData>;
  getVeevaInsciteDocumentTypes?: Maybe<GetInsciteFieldData>;
  getVeevaInsciteDocuments?: Maybe<GetInsciteDocumentsData>;
  getVeevaInscitePicklistValues?: Maybe<GetPicklistValues>;
  getZoomSignature: Scalars['String'];
  importEventsFromVeeva?: Maybe<Array<Event>>;
};


export type QueryExportEventParticipantDataArgs = {
  eventId: Scalars['String'];
};


export type QueryFindSpeakersArgs = {
  limit: Scalars['Float'];
  lookup?: InputMaybe<Scalars['String']>;
  offset: Scalars['Float'];
};


export type QueryGetEmailCompiledHtmlArgs = {
  emailAssetContext: EmailAssetContext;
};


export type QueryGetEventByIdArgs = {
  eventId: Scalars['ObjectId'];
  filter?: InputMaybe<EventsFilterInput>;
};


export type QueryGetEventsArgs = {
  filter?: InputMaybe<EventsFilterInput>;
};


export type QueryGetFullPrivateEventByIdArgs = {
  eventId: Scalars['ObjectId'];
};


export type QueryGetFullPrivateEventsArgs = {
  isPast?: InputMaybe<Scalars['Boolean']>;
  market?: InputMaybe<Scalars['String']>;
  showRsvpEvents?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Source>;
};


export type QueryGetFullPrivateEventsCountArgs = {
  eventFilters?: InputMaybe<EventsFilterInput>;
  showRsvpEvents?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Source>;
};


export type QueryGetParticipantByMdmIdArgs = {
  mdmId: Scalars['String'];
};


export type QueryGetPossibleOrganizersArgs = {
  name: Scalars['String'];
};


export type QueryGetRsvpEventArgs = {
  veevaEventId: Scalars['String'];
};


export type QueryGetS3FileArgs = {
  title: Scalars['String'];
};


export type QueryGetScreenshotsArgs = {
  eventId: Scalars['ObjectId'];
};


export type QueryGetSecretArgs = {
  key: Scalars['String'];
};


export type QueryGetSpeakerByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetSurveyLinkArgs = {
  eventId: Scalars['String'];
};


export type QueryGetSurveysIdArgs = {
  market: Market;
};


export type QueryGetVeevaInsciteDocumentTypeArgs = {
  documentType: Scalars['String'];
};


export type QueryGetVeevaInsciteDocumentTypesArgs = {
  parentType?: InputMaybe<Scalars['String']>;
};


export type QueryGetVeevaInsciteDocumentsArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryGetVeevaInscitePicklistValuesArgs = {
  objectType?: InputMaybe<Scalars['String']>;
};


export type QueryGetZoomSignatureArgs = {
  meetingNumber: Scalars['String'];
};


export type QueryImportEventsFromVeevaArgs = {
  filter?: InputMaybe<EventsFilterInput>;
  source?: InputMaybe<Source>;
};

export type RegisterParticipantInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  collectedPhoneNumber?: InputMaybe<Scalars['String']>;
  consentCollection?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  eventId: Scalars['String'];
  externalEventParticipantStatus?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  guestCountryCode?: InputMaybe<Scalars['String']>;
  institution?: InputMaybe<Scalars['String']>;
  joiningChannel?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  mdmId?: InputMaybe<Scalars['String']>;
  nationalId?: InputMaybe<Scalars['String']>;
  professionalId?: InputMaybe<Scalars['String']>;
  registrationLocale?: InputMaybe<Scalars['String']>;
  restricted?: InputMaybe<Scalars['Boolean']>;
  selectedMultiLanguage?: InputMaybe<Scalars['String']>;
  specialty?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  textMessageConsentCollection?: InputMaybe<Scalars['Boolean']>;
  walkInType?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type RegistrationStatusHistory = {
  __typename?: 'RegistrationStatusHistory';
  accepted?: Maybe<Scalars['DateTime']>;
  checkedIn?: Maybe<Scalars['DateTime']>;
  invited?: Maybe<Scalars['DateTime']>;
  rejected?: Maybe<Scalars['DateTime']>;
  requested?: Maybe<Scalars['DateTime']>;
  waitingList?: Maybe<Scalars['DateTime']>;
};

export type RegistrationStatusHistoryInput = {
  accepted?: InputMaybe<Scalars['DateTime']>;
  checkedIn?: InputMaybe<Scalars['DateTime']>;
  invited?: InputMaybe<Scalars['DateTime']>;
  rejected?: InputMaybe<Scalars['DateTime']>;
  requested?: InputMaybe<Scalars['DateTime']>;
  waitingList?: InputMaybe<Scalars['DateTime']>;
};

export enum RequestStatus {
  accepted = 'accepted',
  pending = 'pending',
  rejected = 'rejected'
}

export type Session = {
  __typename?: 'Session';
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  timeZone: Scalars['String'];
};

export type SessionFilterInput = {
  /** Filter events which end date is after/equal to provided date. */
  endsAfter?: InputMaybe<Scalars['DateTime']>;
  /** Filter events which end date is before/equal to provided date. */
  endsBefore?: InputMaybe<Scalars['DateTime']>;
  /** Filter events which start date is after/equal to provided date. */
  startsAfter?: InputMaybe<Scalars['DateTime']>;
  /** Filter events which start date is before/equal to provided date. */
  startsBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SessionInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  timeZone: Scalars['String'];
};

export type SfmcEmailProcessedHtmlResponse = {
  __typename?: 'SfmcEmailProcessedHtmlResponse';
  assetId: Scalars['Float'];
  assetName: Scalars['String'];
  processedHtml?: Maybe<Scalars['String']>;
};

export enum Source {
  backoffice = 'backoffice',
  veeva = 'veeva'
}

export type Speaker = {
  __typename?: 'Speaker';
  _id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  image?: Maybe<Array<AssetsEvent>>;
  lastName: Scalars['String'];
  personalInfosVisibility?: Maybe<Array<PersonalInfosVisibility>>;
  profile?: Maybe<Profile>;
  title?: Maybe<Scalars['String']>;
};

export type SpeakerInput = {
  affiliation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Array<Scalars['String']>>;
  lastName: Scalars['String'];
  personalInfosVisibility?: InputMaybe<PersonalInfosVisibilityInput>;
  specialty?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SpeakerWithRelatedEvents = {
  __typename?: 'SpeakerWithRelatedEvents';
  _id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  image?: Maybe<Array<AssetsEvent>>;
  lastName: Scalars['String'];
  personalInfosVisibility?: Maybe<Array<PersonalInfosVisibility>>;
  profile?: Maybe<Profile>;
  relatedEvents?: Maybe<Array<Event>>;
  title?: Maybe<Scalars['String']>;
};

export type StreamableBuffer = {
  __typename?: 'StreamableBuffer';
  buffer?: Maybe<Scalars['String']>;
};

export type SubmitDocumentStatus = {
  __typename?: 'SubmitDocumentStatus';
  id?: Maybe<Scalars['Float']>;
  props?: Maybe<ErrorResponseError>;
  success?: Maybe<Scalars['Boolean']>;
  upload?: Maybe<ErrorResponseError>;
};

export type SurveyInfos = {
  __typename?: 'SurveyInfos';
  surveyId: Scalars['String'];
  usecaseCategory: Scalars['String'];
  usecaseName: Scalars['String'];
};

export type TestUser = {
  __typename?: 'TestUser';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mdmid?: Maybe<Scalars['String']>;
  vipLink?: Maybe<Scalars['String']>;
};

export type TestUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mdmid?: InputMaybe<Scalars['String']>;
  vipLink?: InputMaybe<Scalars['String']>;
};

export type Testimonial = {
  __typename?: 'Testimonial';
  author: Scalars['String'];
  id: Scalars['String'];
  testimony: Scalars['String'];
};

export type TestimonialInput = {
  author: Scalars['String'];
  id: Scalars['String'];
  testimony: Scalars['String'];
};

export type Transfer = {
  __typename?: 'Transfer';
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  departurePlace: Scalars['String'];
};

export type TransferInput = {
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  departurePlace: Scalars['String'];
};

export enum TransportType {
  boat = 'boat',
  bus = 'bus',
  cab = 'cab',
  car = 'car',
  plane = 'plane',
  train = 'train'
}

export type TransportationRequest = {
  __typename?: 'TransportationRequest';
  departureJourney: Journey;
  returnJourney: Journey;
  status: RequestStatus;
};

export type TransportationRequestInput = {
  departureJourney: JourneyInput;
  returnJourney: JourneyInput;
  status: RequestStatus;
};

export type UpdateEventInput = {
  id: Scalars['String'];
};

export type UpdatedSpeakerInput = {
  _id: Scalars['String'];
  affiliation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Array<Scalars['String']>>;
  lastName: Scalars['String'];
  personalInfosVisibility?: InputMaybe<PersonalInfosVisibilityInput>;
  specialty?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserData = {
  __typename?: 'UserData';
  market?: Maybe<Market>;
  userId: Scalars['String'];
};

export type UserDataInput = {
  market?: InputMaybe<Market>;
};

export type VeevaDocument = {
  __typename?: 'VeevaDocument';
  dataFields?: Maybe<Array<DocumentInputDataField>>;
  id?: Maybe<Scalars['Int']>;
  subtype__v?: Maybe<Scalars['String']>;
  type__v?: Maybe<Scalars['String']>;
};

export type VeevaDocumentInput = {
  dataFields?: InputMaybe<Array<DocumentInputDataFieldInput>>;
  id?: InputMaybe<Scalars['Int']>;
  subtype__v?: InputMaybe<Scalars['String']>;
  type__v?: InputMaybe<Scalars['String']>;
};
