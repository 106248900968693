import { Box } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from 'components/ActionButton';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import { Participant } from 'modules/events/basics/types/events.types';
import useRegisterParticipantGroup from 'modules/events/groups/RegisterParticipantGroup/RegisterParticipantGroup.hook';
import ConfirmationDialog from 'yoda-ui/components/Dialogs/ConfirmationDialog';
import CheckBoxYoda from 'yoda-ui/components/Form/CheckBoxYoda';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import Loader from 'yoda-ui/components/Loader';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

type RegisterParticipantGroupProps = {
  eventId: string;
  participants: Participant[];
  setParticipants: Dispatch<SetStateAction<Participant[] | null>>;
  seats: number;
  collectMobileNumber?: boolean;
};

const RegisterParticipantGroup: FC<RegisterParticipantGroupProps> = (
  { eventId, participants, setParticipants, seats, collectMobileNumber },
) => {
  const { t } = useTranslation();
  const flags = useFlags();

  const {
    registerParticipantGroup,
    submitButtonConfig,
    open,
    setOpen,
    registerParticipantCallback,
    providerFields,
    isSubmitting,
    newParticipant,
    timezoneValue,
    resetForm,
    noSeatsRemainingLabel,
    isRestrictedEvent,
    isHybridEvent,
    handleJoiningChannelChange,
    isMultiLanguage,
  } = useRegisterParticipantGroup(eventId, participants, setParticipants, seats);

  return (
    <YodaFormProvider { ...providerFields }>
      <FormGroup
        title={ t('event_register_participant_group') }
        groupName={ CommonGroups.registerParticipant }
        collapsible={ false }
      >
        <Box marginBottom={ YodaSpacing.large }>
          <TextYoda { ...registerParticipantGroup.participantMdmid } />
        </Box>
        <Box marginBottom={ YodaSpacing.large }>
          <TextYoda { ...registerParticipantGroup.participantMobilePhoneNumber } display={ collectMobileNumber } />
        </Box>
        <Box marginBottom={ YodaSpacing.large }>
          <SelectYoda { ...registerParticipantGroup.participantTimezone } />
        </Box>
        {
          isHybridEvent
          && <Box marginBottom={ YodaSpacing.large }>
            <SelectYoda { ...registerParticipantGroup.participantJoiningChannel }
              onChange={ isRestrictedEvent ? handleJoiningChannelChange : undefined } />
          </Box>
        }
        {
          isHybridEvent && isRestrictedEvent
          && <Box marginBottom={ YodaSpacing.large }>
            <CheckBoxYoda { ...registerParticipantGroup.isPrivilegedParticipant } />
          </Box>
        }
        {
          isMultiLanguage && flags.eventsCweb675EnableParticipantLanguageSelectionForMultiLanguageCountries
          && <Box marginBottom={ YodaSpacing.large }>
            <SelectYoda { ...registerParticipantGroup.participantSelectedMultiLanguage } />
          </Box>
        }
        <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={ YodaSpacing.small }>
          <ActionButton { ...submitButtonConfig } />
        </Box>

        {
          open && newParticipant && (
            <ConfirmationDialog
              dialogTitle={ t('register_partipant_modal_title') }
              onConfirm={
                () => {
                  registerParticipantCallback();
                }
              }
              onConfirmLabel={ isSubmitting ? <Loader center size={ 1 } color={ YodaColors.inherit } /> : t('actions_confirm_label') }
              onDismiss={
                () => {
                  setOpen(false);
                  resetForm();
                }
              }
              onDismissLabel={ t('actions_cancel_label') }
            >

              { noSeatsRemainingLabel ? (<Box marginBottom={ '20px' }>{ noSeatsRemainingLabel }</Box>) : null }

              <Box>{ t('register_partipant_email_label') }: { newParticipant.email } </Box>
              <Box>{ t('register_partipant_firstname_label') }: { newParticipant.firstName } </Box>
              <Box>{ t('register_partipant_lastname_label') }: { newParticipant.lastName } </Box>
              <Box>{ t('register_partipant_mdmid_label') }: { newParticipant.mdmId } </Box>
              <Box>{ t('register_partipant_timezone_label') }: { timezoneValue } </Box>
            </ConfirmationDialog>
          )
        }
      </FormGroup>
    </YodaFormProvider>
  );
};

export default RegisterParticipantGroup;
