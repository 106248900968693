import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { participantFragment } from '../fragments/commonData.fragments';
import { errorToast } from 'basics/utils/toast';
import { Participant } from 'generated/event.types';
import { ParticipantStatus } from 'modules/events/basics/enums/events.enums';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UseClearParticipantStatusMutationResponse = {
  clearParticipantRegistrationStatus: Participant;
};

export type UseClearParticipantStatusMutation = [
  (mdmId: string, eventId: string, email: string, status: ParticipantStatus) => Promise<Participant | null>,
  MutationResult<UseClearParticipantStatusMutationResponse>,
];

export const clearParticipantStatusMutationCall = gql`
mutation clearParticipantStatus($mdmId: String!, $eventId: String!, $email: String!, $status: String!) {
  clearParticipantRegistrationStatus(mdmId: $mdmId, eventId: $eventId, email: $email, status: $status){
    ${participantFragment}
  }
}`;

export const useClearParticipantStatusMutation = (): UseClearParticipantStatusMutation => {
  const { t } = useTranslation();
  const [changeInviteeStatusMutation, state] = useMutation<UseClearParticipantStatusMutationResponse>(clearParticipantStatusMutationCall);

  const executeMutation = useCallback(async (mdmId, eventId, email, status) => {
    try {
      const { data } = await changeInviteeStatusMutation({
        variables: { mdmId, eventId, email, status },
      });
      if (!data?.clearParticipantRegistrationStatus) {
        errorToast(t('errors_standard'));
      }
      return data?.clearParticipantRegistrationStatus || null;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'clearParticipantStatus.ts - useClearParticipantStatusMutation',
        });
        errorToast(t('errors_standard'));
      }
      return null;
    }
  }, [changeInviteeStatusMutation, t]);

  return [executeMutation, state];
};
