import { ApolloError } from '@apollo/client';
import { Dispatch, SetStateAction, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'basics/options/options.types';
import { getTimezoneUtcOffset } from 'basics/options/timezone.options';
import { succesToast, warningToast } from 'basics/utils/toast';
import { Presence, RegisterParticipantInput } from 'generated/event.types';
import eventLocaleOptions from 'modules/events/basics/options/eventLocale.options';
import { Participant } from 'modules/events/basics/types/events.types';
import { HybridMeetingDataState } from 'modules/events/basics/types/hybridMeeting.types';
import { FILTER_ACCEPTED, filterParticipants } from 'modules/events/basics/utils/participants.utils';
import { useAddParticipantMutation } from 'modules/events/graphql/mutations/addParticipant';
import useLazyGetFullPrivateEventById from 'modules/events/graphql/queries/getLazyFullPrivateEventById';
import useLazyGetParticipantByMdmId from 'modules/events/graphql/queries/getLazyParticipantByMdmId';
import useRegisterParticipantGroupConfig from 'modules/events/groups/RegisterParticipantGroup/RegisterParticipantGroup.config';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useRegisterParticipantGroup = (
  eventId: string,
  participants: Participant[],
  setParticipants: Dispatch<SetStateAction<Participant[] | null>>,
  seats: number,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [newParticipant, setNewParticipant] = useState<Participant | null>(null);

  const { queryGetParticipantByMdmId } = useLazyGetParticipantByMdmId();
  const { queryGetFullPrivateEventById, data: event } = useLazyGetFullPrivateEventById();
  const [runAddMutation] = useAddParticipantMutation();
  useEffect(() => {
    if (eventId) {
      queryGetFullPrivateEventById({ variables: { eventId } });
    }
  }, [eventId, queryGetFullPrivateEventById]);
  const isHybridEvent = event?.presence === Presence.hybrid;
  const isRestrictedEvent = (event?.data as HybridMeetingDataState)?.isRestrictedAccess;

  const { t } = useTranslation();

  const [isMultiLanguage, setIsMultiLanguage] = useState<boolean>(false);
  useEffect(() => {
    if (event && eventLocaleOptions(event.market).length > 1) {
      setIsMultiLanguage(true);
    }
  }, [event]);

  const {
    registerParticipantGroup,
    setIsPrivilegedParticipant,
    setIsPriviligedParticipantDisabled,
  } = useRegisterParticipantGroupConfig(event?.market);

  const {
    getValues,
    providerFields,
    useWatchForm,
    resetForm,
  } = useYodaCreateForm();

  const { isValid: isFormValid } = useWatchForm();

  useEffect(() => {
    setIsDisabled(!isFormValid);
  }, [isFormValid]);

  const handleSubmit = async () => {
    const formValues = getValues();
    try {
      const { data: { getParticipantByMdmId: newParticipantData } } = await queryGetParticipantByMdmId({ variables: {
        mdmId: formValues.participantMdmid,
      } });
      if (newParticipantData.email) {
        newParticipantData.collectedPhoneNumber = formValues.participantMobilePhoneNumber;
        newParticipantData.joiningChannel = formValues.participantJoiningChannel;
        newParticipantData.restricted = !formValues.isPrivilegedParticipant;
        newParticipantData.selectedMultiLanguage = formValues.participantSelectedMultiLanguage;
        setNewParticipant(newParticipantData);
        setOpen(true);
      } else {
        warningToast(t('error_missing_veeva_email'));
      }
    } catch (error) {
      warningToast((error as ApolloError).graphQLErrors[0]?.extensions.description as string || t('errors_standard'));
    }
  };

  const registerParticipantCallback = async () => {
    setIsSubmitting(true);
    const formValues = getValues();

    if (newParticipant) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
      const { id, phone, zoomCallLink, registrationStatusHistory, cxEmailSent, registrationEmailSent, invitationEmailSent,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
        reminder1h, reminder12h, reminder24h, reminder48h, reminder1week, reminderSMS, accountId, attendedZoom, attendedZoomDuration,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
        lastVeevaSync, lastVeevaSyncError, veevaSyncStatus, ...registerParticipantInput } = newParticipant;
      const success = await runAddMutation({ ...registerParticipantInput, eventId } as RegisterParticipantInput,
        formValues.participantTimezone);
      await queryGetFullPrivateEventById({ variables: { eventId } });

      if (success && event?.data.participants) {
        setParticipants(event.data.participants);

        succesToast(t('event_register_participant_sucess'));
      }
    }
    resetForm();
    setIsSubmitting(false);
    setOpen(false);
  };

  const handleJoiningChannelChange = (clickEvent: SyntheticEvent<Element, Event>, value: SelectOption) => {
    setIsPrivilegedParticipant(value.id === Presence.physical);
    setIsPriviligedParticipantDisabled(value.id === Presence.physical);
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleSubmit,
      buttonType: ButtonType.primary,
      disabled: isDisabled,
    },
    label: t('actions_add_label'),
    loading: isSubmitting,
  };

  const itemsAcceptedCount = useMemo(
    () => filterParticipants(participants, FILTER_ACCEPTED).length,
    [participants],
  );

  const noSeatsRemainingLabel = seats > 0 && (seats - itemsAcceptedCount) <= 0 ? t('no_seats_remaining_confirmation_label') : undefined;
  const UTCoffset = getTimezoneUtcOffset(getValues().participantTimezone);

  return {
    registerParticipantGroup,
    submitButtonConfig,
    open,
    setOpen,
    registerParticipantCallback,
    isSubmitting,
    providerFields,
    newParticipant,
    timezoneValue: UTCoffset,
    resetForm,
    noSeatsRemainingLabel,
    isRestrictedEvent,
    isHybridEvent,
    handleJoiningChannelChange,
    isMultiLanguage,
  };
};

export default useRegisterParticipantGroup;
